import './Home.scss'
import Layout from '../Layout/Layout'
import Card from './Card/Card'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ThemeSwitch from './ThemeSwitch/ThemeSwitch'

const Home = () => {
  const frontendSkills = [
    'HTML',
    'JavaScript',
    'React',
    'CSS',
    'Bootstrap',
    'MUI',
  ]

  const backendSkills = [
    'NodeJS',
    'MongoDB',
    'MySQL',
    'Express',
    'AWS',
    'Mongoose',
  ]

  const devOpsSkills = ['Linux', 'Docker', 'LXC', 'Server-Virtualisierung']

  return (
    <Layout>
      <div id="divHome">
        <div id="divBegruesung">
          <div className="labelSmall">Hallo, ich bin</div>
          <div className="labelBig">Pascal Brennich</div>
          <div className="labelSmall">Fullstack JavaScript Entwickler</div>
        </div>
        <div className="buttonWrapper">
          <div className="button btnTransparent">Download CV</div>
          <div className="button btnBackground">Kontakt</div>
        </div>
        {/* <div className="themeSwitchWrapper">
          <ThemeSwitch className="themeSwitch" />
        </div> */}
        <Card title="Frontend Skills">
          <div className="cardContent">
            {frontendSkills.map((skill) => (
              <div key={skill}>
                <CheckCircleOutlineIcon />
                {skill}
              </div>
            ))}
          </div>
        </Card>
        <Card title="Backend Skills">
          <div className="cardContent">
            {backendSkills.map((skill) => (
              <div key={skill}>
                <CheckCircleOutlineIcon />
                {skill}
              </div>
            ))}
          </div>
        </Card>
        <Card title="DevOps Skills">
          <div className="cardContent">
            {devOpsSkills.map((skill) => (
              <div key={skill}>
                <CheckCircleOutlineIcon />
                {skill}
              </div>
            ))}
          </div>
        </Card>
      </div>
    </Layout>
  )
}

export default Home
