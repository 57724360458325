// import Header from '../Header/Header'
import { useSelector } from 'react-redux'
import './Layout.scss'

const Layout = (props) => {
  const darkMode = useSelector((state) => state.darkMode)

  return (
    <div id={darkMode ? 'layoutDark' : 'layout'}>
      {/* <Header /> */}
      <div className="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="content">{props.children}</div>
    </div>
  )
}

export default Layout
