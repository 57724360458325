import * as types from '../type/types.js'
import * as darkMode from './darkMode.js'

const defaultState = {
  darkMode: false,
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_DARKMODE:
      return darkMode.set(state, action)
    default:
      return state
  }
}

export default reducer
