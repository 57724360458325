import './Card.scss'

const Card = ({ title, children }) => {
  return (
    <div className="card">
      <div className="title">
        <div className="labelHeadline">{title}</div>
      </div>
      <div className="cardBody">{children}</div>
    </div>
  )
}

export default Card
